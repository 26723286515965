<template>
  <section
    id="PravoberegHowToBuyBlock"
    class="how-tobuy"
    :class="$customSectionClasses(props.cfgs)"
  >
    <div class="container">
      <div
        v-if="values.title"
        class="h1"
      >
        {{ values.title }}
      </div>
      <div class="how-tobuy__content">
        <CalcOfIpoteka
          :extras="extras"
          :values="values"
          type="sliders"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { SectionTypes } from '~/plugins/customSectionClasses';
import type { ExtrasCalcBlock } from '~/types/MainCalcBlockType';
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { HowToBuyBlockValuesType } from '~/types/HowToBuyTypes';

const CalcOfIpoteka = defineAsyncComponent(() => import('~/components/calc-block/CalcOfIpoteka.vue'));

const { $customSectionClasses } = useNuxtApp();

type IPravoberegHowToBuyBlockProps = Omit<IBlockDefaultProps, 'values' | 'cfgs' | 'extras'> & {
	values: HowToBuyBlockValuesType;
	cfgs: SectionTypes;
	extras: ExtrasCalcBlock;
}

const props = defineProps<IPravoberegHowToBuyBlockProps>();

</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.how-tobuy {
	& .h1 {
		margin-bottom: 30px;
	}
}

.how-tobuy__tabs {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
}

.how-tobuy__top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;

	& > .title_right {
		color: var(--primary);
		font-size: 20px;
		font-weight: 600;
		line-height: 40px;
		text-transform: uppercase;
	}
}

.how-tobuy__tab {
	cursor: pointer;
	padding: 10px 15px;
	border-radius: 15px;
	background: var(--stroke);
	color: var(--black-light);
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;

	&.active {
		background: var(--blue);
		color: white;
	}
}

.how-tobuy__first {
	display: flex;
	flex-direction: column;
	gap: 60px;
}

@include media('md') {
	.how-tobuy__top {
		& > .title_right {
			display: none;
		}
	}

	.how-tobuy__tabs {
		flex-wrap: nowrap;
		overflow-x: auto;
	}

	.how-tobuy__tab {
		white-space: nowrap;
	}
}
</style>
